import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateUrl, parseEnUrl, parseIdUrl } from "../../utils/Helper";
import Img from "../Img";

interface IProps {
    lang: any
    langCode?: string
    isFooterPushed?: boolean
    footerExtraPadding?: string
    newFooterType?: string
}

const currentYear = new Date().getFullYear()

export default function Footer({ lang, langCode, isFooterPushed, footerExtraPadding, newFooterType = '' }: IProps) {
    return (
        <>
            {
                newFooterType === 'homepage' ?
                    <div className="bg-white md:hidden">
                        <div className="bg-gray-F2F2F2 h-2"></div>
                        <div className="flex flex-col text-sm text-black-48 px-5 py-10">
                            <a href={generateUrl(langCode || 'id', '/listing')} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">{lang.list_footer}</a>
                            <a href={generateUrl(langCode || 'id', '/terms')} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">{lang.tnc}</a>
                            <a href={'https://jendela360.com/info/panduan-penyewa'} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">{lang.renter_guide}</a>
                            <a href={'https://jendela360.com/info/panduan-pemilik-sewaan'} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">{lang.owner_guide}</a>
                            <a href={generateUrl(langCode || 'id', '/contact-us')} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">{lang.contact_us}</a>
                            <a href={generateUrl(langCode || 'id', '/faq')} className="mb-3 rounded-lg font-bold border border-gray-dd p-14px">F.A.Q</a>
                            <a href={generateUrl('id', '/info')} className="mb-0 rounded-lg font-bold border border-gray-dd p-14px">{lang.info}</a>
                        </div>
                    </div>
                    : null
            }
            <footer className={`w-full bg-main text-white py-4 ${isFooterPushed ? 'mt-auto' : ''}`}>
                <div className={`flex flex-col justify-center w-full container ${footerExtraPadding || ''}`}>
                    {
                        newFooterType === 'homepage' ?
                            null :
                            <div className="px-3 lg:hidden">
                                <a href={generateUrl(langCode || 'id', '/contact-us')} className="flex flex-row items-center text-sm mb-1">
                                    <FontAwesomeIcon icon='envelope' width='0' size="lg" className="mr-2" />
                                    <p className="text-sm">contact@jendela360.com</p>
                                </a>
                            </div>
                    }
                    <div className={`${newFooterType === 'homepage' ? 'flex' : 'hidden'} lg:flex lg:flex-row flex-col items-start text-white`}>
                        <div className={`${newFooterType === 'homepage' ? 'flex' : 'hidden'} lg:flex flex-col w-full lg:w-1/4 text-sm mr-2`}>
                            <div style={{ paddingTop: '27.33%' }} className="relative mb-4 lg:w-150px w-1/3">
                                <Img withoutSkeletonEffect src={`https://assets.jendela360.com/jendela/assets/images/footer-logo-white.svg`} alt="jendela360" className="w-full h-full object-contain origin-left lg:object-cover" />
                            </div>
                            <p className=" font-bold">PT. Vidi Vici Digital</p>
                            <p>
                                Ruko Komplek Business Park Blok AB-6
                            </p>
                            <p>
                                Jl. Raya Meruya Ilir no 88,
                            </p>
                            <p>
                                Kebon Jeruk, Jakarta Barat
                            </p>
                            <p>
                                Jakarta 11620, Indonesia
                            </p>
                        </div>
                        <div className="w-full border-b border-blue-border mb-4 mt-6 lg:hidden flex" />
                        <div className={`${newFooterType === 'homepage' ? 'hidden' : 'hidden'} lg:flex flex-col w-full lg:w-1/4 text-sm mr-2`}>
                            <p className="font-bold mb-4 uppercase">{lang.browse_apart}</p>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta') : parseEnUrl('jakarta')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} Jakarta</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta-barat') : parseEnUrl('jakarta-barat')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['jakarta-barat']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta-utara') : parseEnUrl('jakarta-utara')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['jakarta-utara']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta-pusat') : parseEnUrl('jakarta-pusat')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['jakarta-pusat']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta-timur') : parseEnUrl('jakarta-timur')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['jakarta-timur']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('jakarta-selatan') : parseEnUrl('jakarta-selatan')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['jakarta-selatan']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('tangerang') : parseEnUrl('tangerang')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} Tangerang</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('tangerang-selatan') : parseEnUrl('tangerang-selatan')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} {lang['tangerang-selatan']}</a>
                            <a href={generateUrl(langCode || 'id', `/${langCode === 'en' ? 'apartment' : 'apartemen'}/${langCode === 'en' ? parseIdUrl('bekasi') : parseEnUrl('bekasi')}`)} className="mb-1 hover:underline">{lang.apartment} {lang.in} Bekasi</a>
                        </div>
                        <div className={`${newFooterType === 'homepage' ? 'hidden' : 'hidden'} lg:flex flex-col w-full lg:w-1/4 text-sm mr-2`}>
                            <p className="font-bold mb-4 uppercase">{lang.discover}</p>
                            <a href={generateUrl(langCode || 'id', '/listing')} className="mb-1 hover:underline">{lang.list_footer}</a>
                            <a href={generateUrl(langCode || 'id', '/terms')} className="mb-1 hover:underline">{lang.tnc}</a>
                            <a href={generateUrl('id', '/info/panduan-penyewa')} className="mb-1 hover:underline">{lang.renter_guide}</a>
                            <a href={generateUrl('id', '/info/panduan-pemilik-sewaan')} className="mb-1 hover:underline">{lang.owner_guide}</a>
                            <a href={generateUrl(langCode || 'id', '/contact-us')} className="mb-1 hover:underline">{lang.contact_us}</a>
                            <a href={generateUrl(langCode || 'id', '/faq')} className="mb-1 hover:underline">F.A.Q</a>
                            <a href={generateUrl(langCode || 'id', '/about-us')} className="mb-1 hover:underline">{lang.about_us}</a>
                            <a href={generateUrl('id', '/info')} className="mb-1 hover:underline">{lang.info}</a>
                            <a href={generateUrl(langCode || 'id', '/career')} className="mb-1 hover:underline">{lang.career}</a>
                        </div>
                        <div className="border-b lg:hidden" />
                        <div className="flex flex-col w-full lg:w-1/4 text-sm">
                            <p className="font-bold mb-4">CUSTOMER SERVICE</p>
                            <div className="flex flex-row items-center text-xs mb-2">
                                <FontAwesomeIcon icon='phone-alt' width='0' size="3x" className="mr-3" />
                                <p className="text-xl">0815-11-360360</p>
                            </div>
                            <p className="text-sm mb-2">{lang.workday}, 08.00-22.00</p>
                            <p className="text-sm mb-2">{lang.workday_weekend}, 08.00-13.00</p>
                            <a href="mailto:contact@jendela360.com" className="flex flex-row items-center text-sm mb-1">
                                <FontAwesomeIcon icon='envelope' width='0' size="lg" className="mr-2" />
                                <p className="text-sm hover:underline">contact@jendela360.com</p>
                            </a>
                        </div>
                    </div>
                    <div className="w-full border-b border-blue-border mb-4 mt-6" />
                    <div>
                        <div className="flex flex-row items-center justify-center mb-2">
                            <a aria-label="facebook" href="https://www.facebook.com/jendela360" target="_blank" className="mr-6"><FontAwesomeIcon icon={['fab', 'facebook']} width="0" size="lg" className="hover:text-gray-e6" /></a>
                            <a aria-label="twitter" href="https://twitter.com/jendela360" target="_blank" className="mr-6"><FontAwesomeIcon icon={['fab', 'twitter']} width="0" size="lg" className="hover:text-gray-e6" /></a>
                            <a aria-label="pinterest" href="https://id.pinterest.com/jendela360" target="_blank" className="mr-6"><FontAwesomeIcon icon={['fab', 'pinterest']} width="0" size="lg" className="hover:text-gray-e6" /></a>
                            <a aria-label="instagram" href="https://www.instagram.com/jendela360" target="_blank"><FontAwesomeIcon icon={['fab', 'instagram']} width="0" size="lg" className="hover:text-gray-e6" /></a>
                        </div>
                        <p className="text-xs text-center px-3">
                            {/* TODO: privacy policy dihilangkan dulu */}
                            © {currentYear} Jendela360. All rights reserved. <br className={newFooterType === 'homepage' ? 'block lg:hidden' : 'hidden'} /> <a href={generateUrl(langCode || 'id', '/terms')} className={`${newFooterType === 'homepage' ? 'inline-block' : 'hidden'} lg:inline-block text-yellow-footer`}>Terms of Use</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}